import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Test2, Test3, Test4, Test5 } from "../Canva/Factories";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useRef, useState } from "react";

const ShopTheLook = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<any>(null);

  const handleSlideChange = (swiper: any) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <div className="container mx-auto flex flex-col items-center">
      <h2 className="text-4xl">Shop The Look</h2>
      <Swiper
        pagination={{
          type: "custom",
        }}
        navigation={{
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper w-full h-full"
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        <SwiperSlide>
          <Test2 />
        </SwiperSlide>
        <SwiperSlide>
          <Test5 />
        </SwiperSlide>
        <SwiperSlide>
          <Test3 />
        </SwiperSlide>
        <SwiperSlide>
          <Test4 />
        </SwiperSlide>
      </Swiper>
      <div className="w-full mt-2.5 flex justify-center gap-10 select-none max-w-7xl">
        <div className="flex w-full items-center">
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              className="h-[6px] cursor-pointer flex items-center flex-1"
              onClick={() => swiperRef.current?.slideTo(index)}
            >
              <span
                className={`flex-1 h-[1px] bg-icon ${
                  activeIndex === index ? "h-[6px]" : "h-[1px]"
                }`}
              ></span>
            </div>
          ))}
        </div>

        <div className="flex gap-10">
          <div className="custom-prev cursor-pointer">
            <IoIosArrowBack className="font-bold text-3xl text-icon" />
          </div>
          <div className="custom-next cursor-pointer">
            <IoIosArrowForward className="font-bold text-3xl text-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopTheLook;
