import CarouselSlider from "@/components/Shares/CarouselSlider";

const PopularProducts = () => (
  <div className="flex flex-col items-center bg-orange-50 w-full py-16 mt-6">
    <h2 className="text-4xl">Popular Products</h2>
    <p className="text-lg mt-2">Explore Hot Picks, Furnish Your Future!</p>
    <div className="py-8 w-full overflow-hidden">
      <CarouselSlider />
    </div>
  </div>
);

export default PopularProducts;
