import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import slideData from "@/data/carousel_slider_data";
import EffectCarousel from "./effect-carousel.esm";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/virtual";
import Image from "next/image";
import Link from "next/link";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function CarouselSlider() {
  return (
    <div className="relative flex items-center justify-center select-none">
      <div className="custom-prev cursor-pointer">
        <IoIosArrowBack className="font-bold text-6xl text-icon" />
      </div>
      <Swiper
        modules={[Autoplay, Navigation, EffectCarousel]}
        effect="carousel"
        grabCursor={true}
        loop={true}
        slidesPerView="auto"
        navigation={{
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        }}
        pagination
        autoplay={{ delay: 5000 }}
      >
        {slideData.map((slide) => (
          <SwiperSlide key={slide.sku}>
            <Link href={slide.link}>
              <div className="swiper-carousel-animate-opacity">
                <Image
                  src={slide.imageSrc}
                  alt={slide.title}
                  width={672}
                  height={384}
                />
                <div className="slide-content">
                  <h2>{slide.title}</h2>
                  <p>{slide.description}</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-next cursor-pointer">
        <IoIosArrowForward className="font-bold text-6xl text-icon" />
      </div>
    </div>
  );
}
