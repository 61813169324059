import Canva from "../Canva";

const hotspotData = [
  {
    id: 1,
    x: "22%",
    y: "44%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1020768%20%281%29.png",
      name: "Kiama Bed -Double",
      price: 439.99,
      link: "/product/kiama-bed-double",
    },
  },
  {
    id: 2,
    x: "50%",
    y: "44%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030629%20%285%29.png",
      name: "Botany Hanging Wardrobe With Drawers 800 - Oak",
      price: 319.99,
      link: "/product/botany-hanging-wardrobe-with-drawers-800",
    },
  },
  {
    id: 3,
    x: "62%",
    y: "44%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030632%20%285%29.png",
      name: "Botany 5 Shelves Wardrobe 800 - Oak",
      price: 249.99,
      link: "/product/botany-5-shelves-wardrobe-800",
    },
  },
  {
    id: 4,
    x: "72%",
    y: "44%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030631%20%2815%29.png",
      name: "Botany 5 Drawer Shelves 600 - Oak",
      price: 249.99,
      link: "/product/botany-wardrobe-600",
    },
  },
  {
    id: 5,
    x: "84%",
    y: "44%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030630%20%2813%29.png",
      name: "Botany 5 Drawer Shelves 600 - Oak",
      price: 299.99,
      link: "/product/botany-5-drawer-shelves-600",
    },
  },
];

const Test3 = () => {
  return <Canva image={"/test3.webp"} hotspotData={hotspotData} />;
};

export default Test3;
