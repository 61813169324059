import Canva from "../Canva";

const hotspotData = [
  {
    id: 1,
    x: "10%",
    y: "50%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/_3050813%20%281%29.png",
      name: "Fiona Sofa 1 Seat - Pink Velvet",
      price: 399.99,
      link: "/product/fiona-sofa-1-seat-pink-velvet",
    },
  },
  {
    id: 2,
    x: "46%",
    y: "50%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/_3050812.png",
      name: "Fiona Sofa 2 Seat - Pink Velvet",
      price: 719.99,
      link: "/product/fiona-sofa-2-seat-pink-velvet",
    },
  },
  {
    id: 3,
    x: "50%",
    y: "63%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/3010315%20%285%29.png",
      name: "Celine Coffee Table Set",
      price: 499.99,
      link: "/product/celine-coffee-table-set",
    },
  },
  {
    id: 4,
    x: "85%",
    y: "50%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/_3050812.png",
      name: "Fiona Sofa 2 Seat - Pink Velvet",
      price: 719.99,
      link: "/product/fiona-sofa-2-seat-pink-velvet",
    },
  },
];

const Test5 = () => {
  return <Canva image={"/test5.webp"} hotspotData={hotspotData} />;
};

export default Test5;
