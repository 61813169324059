import Service from "./components/Service";
import { LiaWalletSolid } from "react-icons/lia";
import { GiReturnArrow } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";

const Banner = () => (
  <div className="bg-orange-50 w-screen">
    <div className="container px-2 mx-auto py-6 md:py-16">
      <div className="container mx-auto grid grid-cols-1 gap-6 md:grid-cols-3 transition-all duration-500">
        <Service
          icon={<LiaWalletSolid />}
          title="Finance Available"
          description="Special financing and earn rewards."
          link="/"
        />
        <Service
          icon={<GiReturnArrow />}
          title="30 Days Guarantee"
          description="30-days free return policy."
          link="/"
        />
        <Service
          icon={<RiSecurePaymentLine />}
          title="Secured Payments"
          description="We accept all major credit cards."
          link="/"
        />
      </div>
    </div>
  </div>
);

export default Banner;
