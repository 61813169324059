import Canva from "../Canva";

const hotspotData = [
  {
    id: 1,
    x: "20.5%",
    y: "65%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030626%20%281%29.png",
      name: "Jessie Dresser Table with Mirror",
      price: 799.98,
      link: "/product/jessie-dresser-table-with-mirror",
    },
  },
  {
    id: 2,
    x: "40%",
    y: "55%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030625.png",
      name: "Jessie 5 Drawer Chest Tallboy",
      price: 549.99,
      link: "/product/jessie-5-drawer-chest-tallboy",
    },
  },
  {
    id: 3,
    x: "73%",
    y: "47%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1020770-3.jpg",
      name: "Jessie Bedroom Double",
      price: 359.99,
      link: "/product/jessie-bedroom-double",
    },
  },
  {
    id: 4,
    x: "90%",
    y: "68%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030625%20%285%29.png",
      name: "Jessie Night Stand",
      price: 229.99,
      link: "/product/jessie-night-stand",
    },
  },
];
const Test = () => {
  return <Canva image={"/test2.webp"} hotspotData={hotspotData} />;
};

export default Test;
