import { useEffect, useRef } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

interface AnimationProps {
  currentIndex: number;
  setCurrentIndex: (e: number) => void;
}

const Animation: React.FC<AnimationProps> = (props) => {
  const { currentIndex, setCurrentIndex } = props;
  const slides = [
    //6
    {
      // backgroundImage: `url('/slide6.webp')`,
      backgroundImage: `url('/school5.webp')`,
    },
    //1
    {
      // backgroundImage: `url('/hero.webp')`,
      backgroundImage: `url('/school6.webp')`,
    },
    //2
    {
      // backgroundImage: `url('/slide2.webp')`,
      backgroundImage: `url('/school1.webp')`,
    },
    //3
    {
      // backgroundImage: `url('/slide4.webp')`,
      backgroundImage: `url('/school2.webp')`,
    },
    //4
    {
      // backgroundImage: `url('/slide3.webp')`,
      backgroundImage: `url('/school3.webp')`,
    },
    //5
    {
      // backgroundImage: `url('/slide5.webp')`,
      backgroundImage: `url('/school4.webp')`,
    },
  ];

  const sliderRef = useRef<HTMLUListElement | null>(null);

  const handleNext = () => {
    const slider = sliderRef.current;
    if (slider) {
      const items = slider.querySelectorAll(".item");
      slider.append(items[0]);
      const newIndex =
        currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }
  };

  const handlePrev = () => {
    const slider = sliderRef.current;
    if (slider) {
      const items = slider.querySelectorAll(".item");
      slider.prepend(items[items.length - 1]);
      const newIndex =
        currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 8000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="w-full h-[80vh] transition-all duration-500 grid place-items-center overflow-hidden">
      <div className="relative w-full h-full shadow-lg">
        <ul ref={sliderRef}>
          {slides.map((slide, index) => (
            <li
              key={index}
              className={`item item-${index + 1}`}
              style={{ backgroundImage: slide.backgroundImage }}
            ></li>
          ))}
        </ul>
        <nav className="nav">
          <IoArrowBack
            className="btn prev text-5xl"
            name="arrow-back-outline"
            onClick={handlePrev}
          />
          <IoArrowForward
            className="btn next text-5xl"
            name="arrow-forward-outline"
            onClick={handleNext}
          />
        </nav>
      </div>
    </div>
  );
};

export default Animation;
