"use client";
import { formatPrice } from "@/utils/format";
import { Image } from "@nextui-org/react";
import Link from "next/link";
import { useState, useRef, useEffect } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";

interface IProductShow {
  image: string;
  name: string;
  price: number;
  link: string;
}

interface IHotspot {
  id: number;
  x: string;
  y: string;
  product: IProductShow;
}

interface HotspotImageProps {
  image: string;
  hotspotData: IHotspot[];
}

const HotspotImage: React.FC<HotspotImageProps> = (props) => {
  const { image, hotspotData } = props;
  const [activeHotspot, setActiveHotspot] = useState<number>(0);
  const imageRef = useRef(null);

  useEffect(() => {
    function updateSize() {
      if (imageRef.current) {
        // Ensure the image container resizes correctly
      }
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const toggleHotspot = (id: number) => {
    setActiveHotspot(activeHotspot === id ? 0 : id);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="relative w-full max-w-7xl mx-auto">
        {/* Image */}
        <img
          ref={imageRef}
          className="block w-full h-auto object-contain"
          src={image}
          alt="Student Accomodation"
          onClick={() => setActiveHotspot(0)}
        />

        {/* Hotspots */}
        {hotspotData.map((hotspot) => (
          <div
            key={hotspot.id}
            className="absolute cursor-pointer transform -translate-x-1/2 -translate-y-1/2"
            style={{ top: hotspot.y, left: hotspot.x }}
            onClick={() => toggleHotspot(hotspot.id)}
          >
            {/* Hotspot Button */}
            <span className="relative flex size-5 select-none">
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-black opacity-75"></span>
              <span className="relative inline-flex size-5 rounded-full bg-white"></span>
            </span>

            {/* Hotspot Label */}
            {activeHotspot === hotspot.id && (
              <Link
                href={hotspot.product.link}
                target="_blank"
                rel="noreferrer"
              >
                <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 bg-white shadow-lg p-4 rounded-md w-80 text-sm z-10">
                  <div className="flex items-center justify-between">
                    <Image
                      src={hotspot.product.image}
                      className="size-20"
                      radius="none"
                    />
                    <div className="flex-col flex-1 px-4 justify-between hidden md:flex">
                      <p>{hotspot.product.name}</p>
                      <span>{formatPrice(hotspot.product.price)}</span>
                    </div>
                    <IoIosArrowRoundForward className="text-3xl" />
                  </div>
                </div>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HotspotImage;
