import Canva from "../Canva";

const hotspotData = [
  {
    id: 1,
    x: "34%",
    y: "55%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1030091%20%282%29.png",
      name: "Coogee Double Bed - Natural Oak",
      price: 599.99,
      link: "/product/coogee-double-bed-natural-oak",
    },
  },
  {
    id: 2,
    x: "74%",
    y: "68%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1030091%20%282%29.png",
      name: "Coogee Double Bed - Natural Oak",
      price: 599.99,
      link: "/product/coogee-double-bed-natural-oak",
    },
  },
];
const Test = () => {
  return <Canva image={"/test1.webp"} hotspotData={hotspotData} />;
};

export default Test;
