import Canva from "../Canva";

const hotspotData = [
  {
    id: 1,
    x: "8%",
    y: "64%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030348%20%287%29.png",
      name: "Jason Nightstand - Walnut",
      price: 159.2,
      link: "/product/jason-nightstand-walnut",
    },
  },
  {
    id: 2,
    x: "28%",
    y: "37%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1020757%20%282%29.png",
      name: "Jason Double Bed Walnut",
      price: 599.99,
      link: "/product/jason-double-bed",
    },
  },
  {
    id: 3,
    x: "63%",
    y: "45%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030465.png",
      name: "Jason 5-D Chest - Walnut",
      price: 349.99,
      link: "/product/jason-5-drawer-wooden-tallboychest",
    },
  },
  {
    id: 4,
    x: "87%",
    y: "58%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030527%20%285%29.png",
      name: "Jason Dresser with Mirror - Walnut",
      price: 649.98,
      link: "/product/jason-dresser-with-mirror",
    },
  },
];
const Test = () => {
  return <Canva image={"/test4.webp"} hotspotData={hotspotData} />;
};

export default Test;
