import Canva from "../Canva";

const hotspotData = [
  {
    id: 1,
    x: "20%",
    y: "31%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/1020745%20%281%29.png",
      name: "Jason Double Bed",
      price: 599.99,
      link: "/product/jason-double-bed",
    },
  },
  {
    id: 2,
    x: "53%",
    y: "45%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030348%20%287%29.png",
      name: "Jason Nightstand - Walnut",
      price: 159.2,
      link: "/product/jason-nightstand-walnut",
    },
  },
  {
    id: 3,
    x: "85%",
    y: "32%",
    product: {
      image:
        "https://shipnow-bucket.s3.ap-southeast-2.amazonaws.com/priceworth/2030461%20%281%29.png",
      name: "Jason 5-D Chest - Dark Oak",
      price: 349.99,
      link: "/product/jason-5-drawer-wooden-tallboychest",
    },
  },
];
const Test = () => {
  return <Canva image={"/test1.webp"} hotspotData={hotspotData} />;
};

export default Test;
